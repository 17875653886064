export enum FormType {
  Login = 'login',
  Register = 'register',
  ResetPassword = 'reset-password',
  SetNewPassword = 'set-new-password'
}

export type SetNewPasswordFormValues = {
  password: string
  confirmPassword: string
}

export type LoginFormValues = {
  username: string
  password: string
}

export type RegistrationFormValues = {
  firstName: string
  lastName: string
  username: string
  email: string
  emailConfirm: string
  password: string
  passwordConfirm: string
}

export type ResetPasswordFormValues = {
  username: string
}

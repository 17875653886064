/**
 * Render the Logo component.
 *
 * @author WebDevStudios
 * @param  {object}  props           Logo props.
 * @param  {string}  props.className Optional classname for the element.
 * @param  {Array}   props.type      Type or style of the logo.
 * @return {Element}                 The Logo component.
 */
type Props = {
  className: string
}

export default function Logo({className}: Props) {
  // prettier-ignore
  /* eslint-disable */
  return (
    <svg width="192" height="20" viewBox="0 0 192 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M15.4582 9.84952C15.4582 4.78796 12.2298 0.547195 6.1012 0.547195H0V19.699H5.93704C12.093 19.7811 15.5129 14.9111 15.4582 9.84952ZM12.9958 9.98632C12.9958 13.9808 10.5608 17.5923 5.90968 17.5923H2.35293V2.6539H5.9644C10.5882 2.6539 12.9958 5.99179 12.9958 9.98632Z" fill="white"/>
      <path d="M33.125 0.547195H30.7721V9.00137H20.6764V0.547195H18.3235V19.699H20.6764V11.1081H30.7721V19.699H33.125V0.547195Z" fill="white"/>
      <path d="M50.9757 17.5923H39.1837L50.5926 2.6539V0.547195H36.5845V2.6539H47.7746L36.2836 17.5923V19.699H50.9757V17.5923Z" fill="white"/>
      <path d="M68.1428 14.1724C68.1428 12.0109 66.8569 10.1231 64.4219 9.32968C66.255 8.53625 67.2946 6.70315 67.2946 4.87004C67.2946 2.54446 65.5436 0.547195 62.1237 0.547195H53.861V19.699H61.7406C65.8446 19.699 68.1428 16.9904 68.1428 14.1724ZM56.1866 8.56361V2.6539H61.768C63.8473 2.6539 64.9417 3.85773 64.9417 5.41724C64.9417 7.08618 63.6284 8.56361 61.3302 8.56361H56.1866ZM65.7351 14.1176C65.7351 16.0602 64.4219 17.5923 61.8227 17.5923H56.1866V10.6156H62.0142C64.3945 10.6156 65.7351 12.1751 65.7351 14.1176Z" fill="white"/>
      <path d="M90.3624 19.699H92.8795L85.1094 0.547195H82.7564L74.9589 19.699H77.4213L79.4733 14.528H88.3104L90.3624 19.699ZM80.3214 12.394L83.9055 3.33789L87.4897 12.394H80.3214Z" fill="white"/>
      <path d="M106.877 19.699L100.913 11.7921L106.658 5.85499H103.676L97.0548 12.8591V0.246238H94.784V19.699H97.0548V15.7866L99.353 13.4063L104.141 19.699H106.877Z" fill="white"/>
      <path d="M119.213 10.0137C119.213 7.03146 117.407 5.60875 114.261 5.60875C110.977 5.60875 108.542 7.22298 108.132 10.041H110.43C110.786 8.50889 112.072 7.60602 114.151 7.60602C115.902 7.60602 116.969 8.26265 116.969 9.60328C116.969 10.8071 116.258 11.1354 114.616 11.4364L111.88 11.9015C110.759 12.093 109.801 12.4761 108.953 13.0233C108.105 13.5705 107.694 14.5554 107.694 15.9781C107.694 18.5226 109.582 20 112.099 20C113.932 20 115.875 19.2886 116.997 17.4555V19.699H119.213V10.0137ZM114.589 13.1327C115.765 12.9412 116.285 12.8044 116.942 12.5034V13.762C116.942 16.4979 114.863 17.9754 112.564 17.9754C111.032 17.9754 109.993 17.3187 109.993 15.7866C109.993 14.145 111.06 13.7072 112.92 13.4063L114.589 13.1327Z" fill="white"/>
      <path d="M134.583 0.246238H132.312V8.12585C131.519 6.59371 129.904 5.60875 127.688 5.60875C123.584 5.60875 121.395 9.05609 121.395 12.8044C121.395 16.58 123.557 20 127.661 20C130.096 20 131.655 18.8235 132.394 16.9904V19.699H134.583V0.246238ZM132.257 12.6949C132.257 14.145 131.874 15.3762 131.136 16.3885C130.397 17.4008 129.33 17.8933 127.934 17.8933C126.539 17.8933 125.499 17.4008 124.788 16.4432C124.077 15.4583 123.721 14.2271 123.721 12.777C123.721 11.3269 124.077 10.0958 124.788 9.11081C125.499 8.12586 126.566 7.63338 127.962 7.63338C129.357 7.63338 130.424 8.12586 131.163 9.08345C131.902 10.041 132.257 11.2449 132.257 12.6949Z" fill="white"/>
      <path d="M149.417 13.3516V12.2845C149.417 7.85226 146.736 5.60875 143.37 5.60875C139.923 5.60875 136.914 7.9617 136.914 12.777C136.914 17.5923 139.896 20 143.343 20C144.738 20 145.997 19.6443 147.119 18.9056C148.24 18.1669 148.952 17.0725 149.307 15.6772H147.064C146.599 17.1272 145.203 17.9754 143.452 17.9754C140.963 17.9754 139.212 16.3338 139.13 13.3516H149.417ZM139.184 11.5185C139.485 8.86457 141.127 7.57866 143.316 7.57866C145.587 7.57866 147.146 9.13817 147.146 11.5185H139.184Z" fill="white"/>
      <path d="M170.884 9.95896C170.884 7.1409 168.997 5.60875 166.261 5.60875C163.661 5.60875 162.43 6.92202 161.856 8.53625C161.445 6.8673 159.886 5.60875 157.779 5.60875C156.055 5.60875 154.687 6.37483 153.949 8.09849V5.85499H151.733V19.699H154.003V12.2025C154.003 9.32969 155.344 7.6881 157.423 7.6881C159.092 7.6881 160.187 8.70041 160.187 10.6977V19.699H162.43V12.2298C162.43 9.35704 163.743 7.6881 165.85 7.6881C167.519 7.6881 168.613 8.70041 168.613 10.6977V19.699H170.884V9.95896Z" fill="white"/>
      <path d="M173.846 2.73598H176.39V0H173.846V2.73598ZM173.982 19.699H176.253V5.85499H173.982V19.699Z" fill="white"/>
      <path d="M191.028 13.3516V12.2845C191.028 7.85226 188.347 5.60875 184.982 5.60875C181.534 5.60875 178.525 7.9617 178.525 12.777C178.525 17.5923 181.507 20 184.954 20C186.35 20 187.608 19.6443 188.73 18.9056C189.852 18.1669 190.563 17.0725 190.919 15.6772H188.675C188.21 17.1272 186.815 17.9754 185.064 17.9754C182.574 17.9754 180.823 16.3338 180.741 13.3516H191.028ZM180.796 11.5185C181.097 8.86457 182.738 7.57866 184.927 7.57866C187.198 7.57866 188.757 9.13817 188.757 11.5185H180.796Z" fill="white"/>
    </svg>
  )
}

import ExitPreview from '@/components/atoms/ExitPreview'
import {AppProvider} from '@/components/common/AppContext'
import {OverlayProvider} from '@/components/common/ProfileOverlayContext'
import {SelectedValueProvider} from '@/components/common/SelectedValueContext'
import WordPressProvider from '@/components/common/WordPressProvider'

import {googleTagManagerCode, useWpApollo} from '@/lib/wordpress/connector'
import '@/styles/index.css'
import {ApolloProvider} from '@apollo/client'
import {SessionProvider as NextAuthProvider} from 'next-auth/react'
import {appWithTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import Script from 'next/script'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'tailwindcss/tailwind.css'
import Cookies from 'universal-cookie'
import {CookiesFormProvider} from '../components/blocks/custom/Cookies/CookiesFormContext'
import {GravityFormProvider} from '../components/molecules/GravityForm/GravityFormContext'
import Loading from '../components/molecules/Loading/Loading'
import * as gtm from '../lib/analytics'
import '../styles/global.scss'
import Custom500 from './500'

/**
 * Render the App component.
 *
 * @author WebDevStudios
 * @param  {object}  props           The component attributes as props.
 * @param  {object}  props.Component Page component to display.
 * @param  {boolean} props.pageProps Page component props.
 * @return {Element}                 The App component.
 */
function App({Component, pageProps}) {
  /**
   * Wrap the app in the ApolloProvider component.
   *
   * @see https://www.apollographql.com/docs/react/api/react/hooks/#the-apolloprovider-component
   */
  const apolloClient = useWpApollo(pageProps)
  const cookies = new Cookies()

  const [loading, setLoading] = useState(false)

  // Check for errors.
  const error = pageProps?.error
  let errorMessage = pageProps?.errorMessage ?? 'An unknown error occurred.'
  // Trim trailing period - added via Error component.
  errorMessage = errorMessage.replace(/\.$/g, '')

  // Extract specific props from page props.
  const {
    defaultSeo: {social, ...defaultSeoData} = {},
    menus,
    algolia,
    preview,
    session,
    themeOptions,
    resources,
    ...passThruProps
  } = pageProps

  const componentProps = {
    ...passThruProps,
    post: {
      ...passThruProps?.post,
      seo: {
        ...passThruProps?.post?.seo,
        siteTitle: defaultSeoData?.openGraph?.siteName,
        siteDescription: defaultSeoData?.description,
        social
      }
    }
  }

  // Initialize state for WordPress context provider.
  const [wp, setWp] = useState({
    algolia: {
      indexName: algolia?.indexName
    },
    menus: menus,
    themeOptions: themeOptions,
    resources,
    post: passThruProps?.post,
    siteTitle: defaultSeoData?.openGraph?.siteName
  })

  useEffect(() => {
    setWp({...wp, post: passThruProps.post, themeOptions})
  }, [passThruProps?.post, themeOptions])

  const router = useRouter()
  const allowStatisticsCookie = cookies.get('allowStatistics')
  useEffect(() => {
    const handleRouteChange = () => {
      const mainDataLayer = {
        pageTypeName: passThruProps?.post?.title || null,
        url: router.pathname
      }
      gtm.pageView(mainDataLayer)

      setLoading(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    router.events.on('routeChangeStart', () => setLoading(true))

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)

      router.events.off('routeChangeStart', () => setLoading(true))
    }
  }, [router.events, passThruProps?.post])

  return (
    <>
      <NextAuthProvider session={session}>
        <ApolloProvider client={apolloClient}>
          <ReactNotifications />
          <CookiesFormProvider>
            <OverlayProvider>
              <SelectedValueProvider>
                <GravityFormProvider>
                  <AppProvider>
                    {loading ? (
                      <Loading />
                    ) : (
                      <WordPressProvider value={wp}>
                        {error ? (
                          <Custom500
                            errorMessage={errorMessage}
                            post={componentProps.post}
                          />
                        ) : (
                          <>
                            <ExitPreview preview={preview} />
                            <Component {...componentProps} />
                          </>
                        )}
                      </WordPressProvider>
                    )}
                  </AppProvider>
                </GravityFormProvider>
              </SelectedValueProvider>
            </OverlayProvider>
          </CookiesFormProvider>
        </ApolloProvider>
      </NextAuthProvider>
      {googleTagManagerCode && (
        <Script
          id="gtag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTagManagerCode}');          `
          }}
        />
      )}
      {googleTagManagerCode && allowStatisticsCookie === 'true' && (
        <Script
          id="consupd"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            gtag('consent', 'update', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
            });
          `
          }}
        />
      )}
    </>
  )
}

export default appWithTranslation(App)

App.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.object.isRequired
}

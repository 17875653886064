import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState
} from 'react'

const defaultState = {
  isFormOpen: false
}

interface GravityFormContextI {
  isFormOpen: boolean
  setIsFormOpen?: Dispatch<SetStateAction<boolean>>
}

export const GravityFormContext =
  createContext<GravityFormContextI>(defaultState)

export const GravityFormProvider = ({
  children
}: PropsWithChildren<Record<string, any>>) => {
  const [isFormOpen, setIsFormOpen] = useState(defaultState.isFormOpen)

  return (
    <GravityFormContext.Provider value={{isFormOpen, setIsFormOpen}}>
      {children}
    </GravityFormContext.Provider>
  )
}

export type MenuItem = {
  id: string
  path: string
  label: string
  target: string
  children: MenuItem[]
}

export enum SelectedValue {
  GebuchteKurse = 'Gebuchte Kurse',
  Kontodetails = 'Kontodetails',
  Adressen = 'Adressen',
  Abmelden = 'Abmelden'
}

export interface Window {
  dataLayer: any
}

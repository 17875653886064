import {NOTIFICATION_TYPE, Store} from 'react-notifications-component'

export const useNotification = () => {
  const addNotification = (
    title: string,
    message: string,
    type: NOTIFICATION_TYPE
  ) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }

  return {addNotification}
}

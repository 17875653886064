import Button from '@/components/atoms/Button/Button'
import Text from '@/components/atoms/Inputs/Text'
import {FormType} from '@/components/types/auth'
import {useTranslation} from 'react-i18next'
import styles from './Profile.module.scss'

type SetNewPasswordFormProps = {
  formikProps: any
  isNewPasswordSuccess?: boolean
  isLoading?: boolean
  handleSwitchFormType: (formType: FormType) => void
  handleCloseForm: () => void
  isCheckout?: boolean
}

const SetNewPasswordForm = ({
  formikProps,
  isNewPasswordSuccess,
  isLoading,
  handleSwitchFormType,
  handleCloseForm,
  isCheckout
}: SetNewPasswordFormProps) => {
  const {t} = useTranslation(['checkout', 'global', 'account'])
  return (
    <>
      {isNewPasswordSuccess ? (
        <>
          <div className={styles.title}>{t('account:passwordChanged')}</div>
          {/* <div className={styles.subtitle}>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </div> */}
          <div className={styles.bottomButtons}>
            <div className={styles.buttonWrapper}>
              {isCheckout ? (
                <Button
                  url="/"
                  tag="button"
                  type="filled"
                  text={t('global:toFrontPageButton')}
                  icon={null}
                />
              ) : (
                <Button
                  tag="button"
                  text={t('account:closeLabel')}
                  type="filled"
                  icon={null}
                  className={styles.button}
                  onClick={() => handleCloseForm()}
                />
              )}
            </div>
            <div className={styles.underlindedButton}>
              <div onClick={() => handleSwitchFormType(FormType.Login)}>
                {t('account:loginLabel')}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.title}> {t('account:NewPasswordLabel')}</div>
          {/* <div className={styles.subtitle}>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </div> */}
          <div className={styles.fields}>
            <Text
              id="password"
              label="Passwort"
              placeholder={t('account:EnterNewPasswordLabel')}
              isRequired
              type="password"
              fullWidth={true}
              setFieldValue={formikProps.setFieldValue}
            />
            <Text
              id="confirmPassword"
              label="Passwort wiederholen"
              placeholder={t('account:ConfirmNewPasswordLabel')}
              isRequired
              type="password"
              fullWidth={true}
              setFieldValue={formikProps.setFieldValue}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              tag="button"
              text={t('account:saveButton')}
              type="filled"
              isLoading={isLoading}
              icon={null}
              className={styles.button}
            />
          </div>
        </>
      )}
    </>
  )
}

export default SetNewPasswordForm

export const slugify = (str) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export const removeProperties = (obj, props) => {
  let result = {...obj}
  props.forEach((prop) => {
    const {[prop]: _, ...rest} = result
    result = rest
  })
  return result
}

export const formatDate = (timestamp) => {
  const date = new Date(timestamp)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()

  return `${day}.${month}.${year}`
}

export function generateRandomUsername() {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomUsername = ''
  const characterCount = characters.length
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characterCount)
    randomUsername += characters[randomIndex]
  }
  return randomUsername
}

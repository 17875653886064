import {SelectedValue} from '@/components/types/types'
import React, {Dispatch, SetStateAction, useContext, useState} from 'react'

interface SelectedValueContextProps {
  selectedValue: SelectedValue
  setSelectedValue: Dispatch<SetStateAction<SelectedValue>>
}

export const SelectedValueContext =
  React.createContext<SelectedValueContextProps>({
    selectedValue: SelectedValue.GebuchteKurse,
    setSelectedValue: () => {}
  })

export const SelectedValueProvider: React.FC<React.PropsWithChildren<{}>> = (
  props
) => {
  const [selectedValue, setSelectedValue] = useState<SelectedValue>(
    SelectedValue.GebuchteKurse
  )

  return (
    <SelectedValueContext.Provider
      value={{
        selectedValue,
        setSelectedValue
      }}
    >
      {props.children}
    </SelectedValueContext.Provider>
  )
}

export const useSelectedValueContext = () => {
  const context = useContext(SelectedValueContext)
  return context
}

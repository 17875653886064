declare const window: Window & {dataLayer: Record<string, unknown>[]}

export const pageView = (rest: any) => {
  if (window && window.dataLayer) {
    window.dataLayer?.push({
      event: 'VirtualPageView',
      ...rest
    })
  }
}

export const commerce_events = (eventName: string, rest: any) => {
  if (window && window.dataLayer) {
    window.dataLayer?.push({
      event: eventName,
      ecommerce: {
        ...rest
      }
    })
  }
}

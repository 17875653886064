export const md = 768

export const lg = 1024

export const xxl = 1440

export const cookieSettings = {
  path: '/',
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
}

export const bannerSettings = {
  path: '/',
  expires: new Date(new Date().setDate(new Date().getDate() + 7))
}

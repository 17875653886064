// Define valid WP post types (singular and plural GraphQL names).
export const postTypes = {
  page: {
    pluralName: 'pages',
    route: ''
  },
  post: {
    pluralName: 'posts',
    route: 'news'
  },
  course: {
    pluralName: 'courses',
    route: 'programm'
  },
  product: {
    pluralName: 'products',
    route: 'course'
  }
}

// Define hierarchical post types.
export const hierarchicalPostTypes = ['page']

export default function ExternalArrow() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1539_3568"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1539_3568)">
        <path
          d="M5.33334 14.7083L4.45834 13.8333L12.6667 5.625H5.20834V4.375H14.7917V13.9583H13.5417V6.5L5.33334 14.7083Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

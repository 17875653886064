import Button from '@/components/atoms/Button/Button'
import Checkbox from '@/components/atoms/Inputs/Checkbox/Checkbox'
import Text from '@/components/atoms/Inputs/Text'
import {FormType} from '@/components/types/auth'
import {useTranslation} from 'react-i18next'
import styles from './Profile.module.scss'

type ResetPasswordFormProps = {
  formikProps: {
    setFieldValue: (field: string, value: any) => void
    values: {
      username: string
      emailOnly: boolean
    }
  }
  handleCloseForm: () => void
  isEmailSuccess?: boolean
  isLoading?: boolean
  handleSwitchFormType: (formType: FormType) => void
  csrfToken: string
  isCheckout?: boolean
}

export default function ResetPasswordForm({
  formikProps,
  handleSwitchFormType,
  handleCloseForm,
  isLoading,
  isEmailSuccess,
  isCheckout
}: ResetPasswordFormProps) {
  const {t} = useTranslation(['checkout', 'global', 'account'])

  return (
    <>
      {isEmailSuccess ? (
        <>
          <div className={styles.title}>{t('account:almostThereLabel')}</div>
          <div className={styles.subtitle}>
            <p>{t('account:emailSent')}</p>
            <p
              className={styles.spamText}
              dangerouslySetInnerHTML={{__html: t('account:checkSpam')}}
            />
            {isCheckout ? (
              <Button
                url="/"
                tag="button"
                type="filled"
                className={styles.button}
                text={t('global:toFrontPageButton')}
                icon={null}
              />
            ) : (
              <Button
                tag="button"
                text={t('account:closeLabel')}
                type="filled"
                icon={null}
                className={styles.button}
                onClick={() => handleCloseForm()}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.title}>{t('account:resetPassword')}</div>
          <div className={styles.subtitle}>{t('account:enterEmailText')}</div>

          <div className={styles.fields}>
            <Text
              id="username"
              label={`${t('checkout:emailLabel')} `}
              placeholder={t('checkout:emailLabel')}
              isRequired
              type="EMAIL"
              fullWidth={true}
              setFieldValue={formikProps.setFieldValue}
            />
          </div>

          <div className={styles.bottomButtons}>
            <div className={styles.sendButtonWrapper}>
              <Button
                tag="button"
                text={t('account:sendLabel')}
                type="filled"
                isLoading={isLoading}
                icon={null}
                disabled={!formikProps.values.username}
              />
            </div>
            <div className={styles.underlindedButton}>
              <div onClick={() => handleSwitchFormType(FormType.Login)}>
                {t('account:cancelLabel')}
              </div>
              <Checkbox
                id="emailOnly"
                label="contact_me_by_email_only"
                className={styles.honey}
                login={true}
                setFieldValue={formikProps.setFieldValue}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

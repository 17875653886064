import {KeyboardArrowLeft} from '@material-ui/icons'
import cn from 'classnames'

import {useEffect, useRef, useState} from 'react'

import {lg} from 'config'

import NavigationItem from '../NavigationItem/NavigationItem'

import styles from './NavigationMenu.module.scss'

import useMediaQuery from '@/functions/useMediaQuery'

import {MenuItem} from '@/components/types/types'

import Icon from '@/components/atoms/Icon'
import {useOverlayContext} from '@/components/common/ProfileOverlayContext'
import {enableScroll} from '@/functions/enableDisableScroll'
import {useSession} from 'next-auth/react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useTranslation} from 'react-i18next'
import LangSwitcher from '../LangSwitcher'

type Props = {
  menu: MenuItem[]
  active: boolean
  menuLegal?: MenuItem[]
  goBack?: () => void
}

export default function NavigationMenu({
  menu,
  menuLegal,
  active,
  goBack
}: Props) {
  const [activeSubMenu, setActiveSubMenu] = useState('')
  const isLg = useMediaQuery(lg)

  const {t} = useTranslation()

  const {data: session} = useSession()

  const {setIsFormOpen} = useOverlayContext()

  const containerRef = useRef<HTMLDivElement>(null)
  const [isScrollable, setIsScrollable] = useState<boolean>(false)

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      setIsScrollable(container.scrollHeight > container.clientHeight)
    }
  }, [active])

  const router = useRouter()

  const handleOpenForm = () => {
    if (session && session.user) {
      router.push('/konto')
    } else {
      enableScroll()
      setIsFormOpen(true)
    }
  }

  const handleScrollUp = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop += 50
    }
  }

  useEffect(() => {
    setActiveSubMenu('')
  }, [active])

  if (!menu || !menu?.length) {
    return null
  }

  const setParentActive = () => {
    setActiveSubMenu('')
  }

  const getClasses = () => {
    if (isLg && active) {
      return styles.active
    } else {
      if (activeSubMenu) {
        return styles.last
      } else if (active) {
        return styles.active
      }
    }
  }

  return (
    <>
      <div ref={containerRef} className={cn(styles.menu, getClasses())}>
        <div>
          {goBack && !isLg && (
            <KeyboardArrowLeft
              className={styles.back}
              onClick={() => goBack()}
            />
          )}
          {menu.map((item, index) => {
            return (
              <NavigationItem
                item={item}
                key={index}
                subMenuActive={activeSubMenu ? true : false}
                selected={activeSubMenu === item.id}
                setActiveMenu={setActiveSubMenu}
              />
            )
          })}
        </div>
        {!goBack && menuLegal && (
          <div>
            <LangSwitcher />

            <div className={styles.profile} onClick={() => handleOpenForm()}>
              <div className={styles.profileIcon}>
                <Icon icon="profile" size={24} sizeViewbox={24} />
              </div>
              <div>{t('global:accountNavLabel')}</div>
            </div>

            <nav className={styles.legal}>
              {menuLegal.map((item, index) => {
                return (
                  <Link href={item.path} key={index}>
                    <a
                      target={item.target ? item.target : '_self'}
                      className={styles.linkLegal}
                    >
                      {item.label}
                    </a>
                  </Link>
                )
              })}
            </nav>
          </div>
        )}
        {isScrollable && isLg && (
          <div onClick={() => handleScrollUp()} className={styles.arrowDown} />
        )}
      </div>
      {isScrollable && !isLg && (
        <div onClick={() => handleScrollUp()} className={styles.arrowDown} />
      )}
      {menu.map((item, index) => {
        const children =
          item.children && item.children.length > 0 ? item.children : ''
        return (
          children && (
            <NavigationMenu
              menu={children}
              active={active && activeSubMenu == item.id}
              goBack={setParentActive}
              key={index}
            />
          )
        )
      })}
    </>
  )
}

import mutationSendResetPassword from '@/lib/wordpress/auth/mutationSendResetPassword'
import {initializeWpApollo} from '@/lib/wordpress/connector'

export default async function SendResetPassword(username) {
  const apolloClient = initializeWpApollo()

  return apolloClient
    .mutate({
      mutation: mutationSendResetPassword,
      variables: {
        sendPasswordResetEmailInput: {
          username
        }
      }
    })
    .then(
      (response) =>
        response?.data?.sendPasswordResetEmail?.user?.username?.length > 0 ?? {
          error: true,
          errorMessage: `An error occurred while trying to send a reset email to the user.`
        }
    )
    .catch((error) => {
      return {
        error: true,
        errorMessage: error.message
      }
    })
}

import Button from '@/components/atoms/Button'

import Container from '@/components/atoms/Container'

import cn from 'classnames'

import Link from 'next/link'

import {useRouter} from 'next/router'

import {useContext, useEffect, useState} from 'react'

import * as gtm from '@/lib/analytics'

import Cookies from 'universal-cookie'

import {cookieSettings} from '../../../../config'

import {enableScroll} from '../../../../functions/enableDisableScroll'

import {CookiesFormContext} from '../Cookies/CookiesFormContext'

import styles from './CookieBanner.module.scss'

const CookieBanner = ({themeOptions}) => {
  const cookies = new Cookies()

  const cookieName = 'allowStatistics'

  const cookieNameFunctional = 'allowFunctional'

  const cookieValue = cookies.get(cookieNameFunctional)

  const [show, setShow] = useState(false)

  const {setIsCookiesFormOpen} = useContext(CookiesFormContext)

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : ''
    if (url.search('cookies') == -1) {
      setShow(!cookieValue ? true : false)
    }
  })

  const router = useRouter()

  const setCookieBanner = (value) => {
    setShow(false)

    if (value) {
      cookies.set(cookieName, value, cookieSettings)
      cookies.set(cookieNameFunctional, value, cookieSettings)
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      })

      gtm.pageView({url: router.asPath})
    }
  }

  const handleCookiesForm = () => {
    setIsCookiesFormOpen(true)
    enableScroll()
  }

  return (
    <div
      className={cn(styles.cookieBanner, show ? styles.fadeIn : styles.fadeOut)}
    >
      <Container removePaddingBottom>
        <div className={styles.inner}>
          <div className={styles.text}>
            {themeOptions?.cookieBannerDescription}
          </div>
          <div className={styles.row}>
            {themeOptions?.acceptLabel && (
              <Button
                className={styles.button}
                onClick={() => setCookieBanner('true')}
                text={themeOptions?.acceptLabel}
                type="filled"
                icon={null}
                isSmall
              />
            )}
            <div className={styles.actions}>
              <div onClick={() => setCookieBanner('false')}>
                {themeOptions?.rejectLabel}
              </div>
              <div onClick={() => handleCookiesForm()}>
                {themeOptions?.cookiesLabelSettings}
              </div>
              {themeOptions?.dataProtectionPageLink && (
                <Link href={themeOptions?.dataProtectionPageLink.url}>
                  <a>{themeOptions?.dataProtectionPageLink.title}</a>
                </Link>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CookieBanner

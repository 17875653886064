import cn from 'classnames'
import React from 'react'

import styles from './Container.module.scss'

type ContainerProps = {
  children: React.ReactNode
  size?: string
  removePaddingBottom?: boolean
  changePaddingBottom?: boolean
  constantPaddingBottom?: boolean
}

export default function Container({
  children,
  size,
  removePaddingBottom,
  changePaddingBottom,
  constantPaddingBottom
}: ContainerProps) {
  return (
    <div
      className={cn(
        styles.container,
        size == 'large' ? styles.large : '',
        removePaddingBottom
          ? styles.removePaddingBottom
          : changePaddingBottom
          ? styles.changePaddingBottom
          : constantPaddingBottom
          ? styles.constantPaddingBottom
          : ''
      )}
    >
      {children && children}
    </div>
  )
}

import {icons} from './icons'

type IconProps = {
  ariaHidden?: boolean
  className?: string
  icon: string
  size?: number
  size2?: number
  sizeViewbox?: number
  style?: 'fill' | 'line'
  title?: string
  onClick?: () => void
  sizeViewbox2?: number
}
export default function Icon({
  ariaHidden,
  className,
  icon,
  size = 40,
  size2,
  sizeViewbox = 40,
  title,
  onClick,
  sizeViewbox2
}: IconProps) {
  return (
    <svg
      fill="none"
      width={size}
      height={size2 ? size2 : size}
      viewBox={`0 0  ${sizeViewbox} ${
        sizeViewbox2 ? sizeViewbox2 : sizeViewbox
      }`}
      aria-hidden={ariaHidden}
      className={className}
      onClick={onClick}
    >
      {title && <title>{title}</title>}
      {icons[icon]}
    </svg>
  )
}

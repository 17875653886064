import Icon from '@/components/atoms/Icon'

import cn from 'classnames'

import {useEffect, useState} from 'react'

import {Collapse} from 'react-collapse'

import Switch from 'react-switch'

import {useTranslation} from 'next-i18next'

import styles from './Cookies.module.scss'

const CookieHelper = ({cookie, object, setObject}) => {
  const [show, setShow] = useState(false)

  const [expand, setExpanded] = useState(false)

  const {t} = useTranslation('global')

  useEffect(() => {
    setShow(object[cookie.cookieName])
  })

  const handleChange = () => {
    object[cookie.cookieName] = !object[cookie.cookieName]
    setObject({...object})
    setShow(!show)
  }

  return (
    <div className={styles.oneCookieWrapper}>
      <div className={styles.nameWrapper}>
        <div className={styles.heading}>{cookie.title}</div>
        <div className={styles.switchWrapper}>
          <Switch
            onChange={() => handleChange()}
            checked={show ? true : false}
            handleDiameter={20}
            disabled={cookie.cookieName === 'allowFunctional'}
            offColor="#BDC3CC"
            onColor="#2FBD77"
            offHandleColor="#ffffff"
            onHandleColor="#ffffff"
            height={24}
            width={44}
            borderRadius={33}
            uncheckedHandleIcon={
              <Icon
                icon="mobileMenuClose"
                title="mobile-menu-close"
                className={styles.icon}
              />
            }
            uncheckedIcon={<> </>}
            checkedIcon={<> </>}
            id="small-radius-switch"
          />
        </div>
      </div>
      <div className={styles.text}>{cookie.description}</div>
      <div
        className={cn(styles.expand, expand ? styles.isExpanded : '')}
        onClick={() => setExpanded(!expand)}
      >
        {t('showCookieLabel')}
        <Icon
          icon="chevronDownLarge"
          title="open Submenu"
          size={24}
          sizeViewbox={24}
        />
      </div>
      <Collapse isOpened={expand}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            {cookie.cookies &&
              cookie.cookies.map((cookieRow, i) => (
                <tbody key={i}>
                  <tr>
                    <th>{t('nameCookieLabel')}</th>
                    <td>{cookieRow.name}</td>
                  </tr>
                  <tr>
                    <th>{t('descriptionCookieLabel')}</th>
                    <td>{cookieRow.description}</td>
                  </tr>
                  <tr>
                    <th>{t('domainCookieLabel')}</th>
                    <td>{cookieRow.domain}</td>
                  </tr>
                  <tr>
                    <th>{t('expiryCookieLabel')}</th>
                    <td>{cookieRow.expires}</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </Collapse>
    </div>
  )
}

export default CookieHelper

import Icon from '@/components/atoms/Icon'
import styles from './Loading.module.scss'

type Props = {
  single?: boolean
}

export default function Loading({single}: Props) {
  return (
    <div className={!single ? styles.fullscreen : styles.isSmall}>
      <div className={styles.spinner}>
        <Icon icon="spinner" size={120} sizeViewbox={120} />
      </div>
    </div>
  )
}

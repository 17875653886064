import {KeyboardArrowRight} from '@material-ui/icons'
import cn from 'classnames'
import Link from 'next/link'
import {useRouter} from 'next/router'

import styles from './NavigationItem.module.scss'

import {MenuItem} from '@/components/types/types'
import isLinkActive from '@/functions/isLinkActive'

type Props = {
  item: MenuItem
  setActiveMenu: (value: string) => void
  selected?: boolean
  subMenuActive?: boolean
}

export default function NavigationItem({
  item,
  setActiveMenu,
  selected,
  subMenuActive
}: Props) {
  const {asPath} = useRouter() ?? {}

  const children =
    item.children && item.children.length > 0 ? item.children : ''

  return (
    <>
      <li
        className={cn(
          children && children?.length ? styles.hasChildren : '',
          styles.item,
          subMenuActive ? styles.opaque : '',
          selected ? styles.active : ''
        )}
        // onMouseEnter={() => setActiveMenu(item.id)}
        // onMouseLeave={() => setActiveMenu('')}
      >
        {children && children?.length ? (
          <a className={cn(styles.link)} onClick={() => setActiveMenu(item.id)}>
            {item.label}
            <KeyboardArrowRight />
          </a>
        ) : (
          <Link href={item.path} locale={false}>
            <a
              target={item.target ? item.target : '_self'}
              className={cn(
                isLinkActive(asPath, item.path) && styles.active,
                styles.link
              )}
            >
              {item.label}
            </a>
          </Link>
        )}
      </li>
    </>
  )
}

import cn from 'classnames'
import {Field} from 'formik'
import {useState} from 'react'
import styles from './Checkbox.module.scss'

type CheckboxProps = {
  className?: string
  id: string | number
  label: string
  name?: string
  isRequired?: boolean
  borderFree?: boolean
  login?: boolean
  setFieldValue: (field: string, value: any) => void
  extensionFieldId?: string
  checked?: boolean
}

export default function Checkbox({
  className,
  id,
  label,
  name,
  login,
  isRequired,
  borderFree,
  setFieldValue,
  extensionFieldId = '',
  checked = false
}: CheckboxProps) {
  const [isChecked, setIsChecked] = useState(checked)
  const [error, setError] = useState(false)
  const handleChange = () => {
    const newChecked = !isChecked
    setIsChecked(newChecked)

    const fieldValue = extensionFieldId ? `${newChecked}` : newChecked
    setFieldValue(`${id}${extensionFieldId}`, fieldValue)

    setError(false)
  }

  return (
    <div
      className={cn(
        className,
        styles.checkbox,
        error ? styles.isError : '',
        borderFree && [styles.borderFree],
        login && [styles.login]
      )}
    >
      {label && (
        <>
          <Field
            id={id}
            name={name}
            type="checkbox"
            onChange={handleChange}
            checked={isChecked}
            required={isRequired}
            onInvalid={() => setError(true)}
          />
          <div
            className={styles.label}
            dangerouslySetInnerHTML={{__html: label}}
          />
        </>
      )}
    </div>
  )
}

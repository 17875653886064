import cn from 'classnames'

import Image from 'next/image'

import {AppContext} from '@/components/common/AppContext'

import {useNotification} from '@/functions/Notifications/useNotification'

import PropTypes from 'prop-types'

import {useContext, useEffect, useState} from 'react'

import Container from '../atoms/Container'

import Banner from '../organisms/Banner'

import Footer from '../organisms/Footer/Footer'

import styles from './Layout.module.scss'

import Meta from '@/components/common/Meta'

import {useWordPressContext} from '@/components/common/WordPressProvider'

import Header from '@/components/organisms/Header'

import {seoPropTypes} from '@/functions/getPagePropTypes'

import UniversalCookies from 'universal-cookie'

import CookieBanner from '../blocks/custom/CookieBanner/CookieBanner'

import Cookies from '../blocks/custom/Cookies/Cookies'

import {useSession} from 'next-auth/react'

import {useRouter} from 'next/router'

import {useTranslation} from 'react-i18next'

import he from 'he'

import {useOverlayContext} from '@/components/common/ProfileOverlayContext'

import {CookiesFormContext} from '../blocks/custom/Cookies/CookiesFormContext'

export default function Layout({children, seo, removeMarginTopFooter}) {
  const {t} = useTranslation(['error'])

  const {menus, themeOptions, post} = useWordPressContext()

  const {setIsCookiesFormOpen} = useContext(CookiesFormContext)

  const {locale: activeLocale} = useRouter()

  const {errorMessage, setErrorMessage, successMessage, setSuccessMessage} =
    useContext(AppContext)

  const {addNotification} = useNotification()

  const cookies = new UniversalCookies()

  const [ssoHTML, setSsoHTML] = useState('')

  const cookieNameFunctional = 'allowFunctional'

  const cookieValue = cookies.get(cookieNameFunctional)

  const {data: session} = useSession()

  const {isFormOpen, setIsFormOpen, isMoodleActivated, setIsMoodleActivated} =
    useOverlayContext()

  const accessToken = session?.user?.accessToken

  const clickAnchorTag = (event) => {
    const href = event.target?.closest('a')
      ? event.target.closest('a').getAttribute('href')
      : ''

    if (href === '#') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else if (
      href &&
      href.length > 0 &&
      href.startsWith('#') &&
      !href.includes('cookie') &&
      !href.includes('moodle')
    ) {
      event.preventDefault()

      const destination = document?.querySelector(href)
      if (destination) {
        const top = destination.offsetTop
        window.scrollTo({
          top: top - 88,
          behavior: 'smooth'
        })
      }
    } else if (
      href &&
      href.length > 0 &&
      href.startsWith('#') &&
      href.includes('moodle')
    ) {
      openMoodle()
    } else if (
      href &&
      href.length > 0 &&
      href.startsWith('#') &&
      href.includes('cookie')
    ) {
      setIsCookiesFormOpen(true)
    }
  }

  useEffect(() => {
    if (isMoodleActivated && accessToken && accessToken?.length > 0) {
      setIsMoodleActivated(false)
      openMoodle()
    }
    if (!isFormOpen && isMoodleActivated) setIsMoodleActivated(false)
  }, [isFormOpen, isMoodleActivated, accessToken, setIsMoodleActivated])
  useEffect(() => {
    const form = document.getElementById('responseform')
    if (form) {
      form.submit()
    }
  }, [ssoHTML])

  const openMoodle = async () => {
    try {
      const response = await fetch('/api/moodleAuth')
      const data = await response.json()
      if (data.missingToken) {
        setIsFormOpen(true)
        setIsMoodleActivated(true)
        return
      }
      const decodedHtml = he.decode(data.data)
      setSsoHTML(decodedHtml)
    } catch (error) {
      console.error('Error opening moodle:', error)
    }
  }

  useEffect(() => {
    window.addEventListener('click', (e) => clickAnchorTag(e))

    return () => {
      window.removeEventListener('click', (e) => clickAnchorTag(e))
    }
  }, [])

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('auth-token', accessToken)
    }
  }, [accessToken])

  useEffect(() => {
    if (errorMessage) {
      addNotification(
        t('error:errorLabel'),
        t(errorMessage, 'Server Error'),
        'danger'
      )
      setErrorMessage('')
    }
    if (successMessage) {
      addNotification(t('success:successLabel'), t(successMessage), 'success')
      setSuccessMessage('')
    }
  }, [errorMessage, successMessage])

  const menu =
    activeLocale === 'en' ? menus?.primary_menu___en : menus?.primary_menu
  const footerMenu =
    activeLocale === 'en' ? menus?.footer_menu___en : menus?.footer_menu
  const legalMenu = activeLocale === 'en' ? menus?.legal___en : menus?.legal
  const checkLatestPostsBlock =
    post?.blocks &&
    post?.blocks[post.blocks.length - 1]?.name === 'acf/latest-posts'
  return (
    <>
      <Meta seo={seo} post={post} />
      <Header menu={menu} menuLegal={legalMenu} />
      {post?.isFrontPage && themeOptions?.teaserImage && (
        <div className={styles.sunBackgroundWrapper}>
          <div className={styles.sunBackground}>
            <Image
              src={themeOptions.teaserImage.mediaItemUrl}
              alt={themeOptions.teaserImage.altText}
              layout="responsive"
              width={themeOptions.teaserImage.mediaDetails.width}
              height={themeOptions.teaserImage.mediaDetails.height}
              priority
            />
          </div>
        </div>
      )}
      {post?.isFrontPage &&
        themeOptions?.bannerText &&
        themeOptions?.bannerLink && (
          <Banner
            text={themeOptions.bannerText}
            link={themeOptions.bannerLink}
          />
        )}

      <Cookies />

      {!cookieValue && <CookieBanner themeOptions={themeOptions} />}

      {ssoHTML && (
        <div
          className={styles.ssoMoodle}
          dangerouslySetInnerHTML={{__html: ssoHTML}}
        />
      )}

      <main
        className={cn(
          styles.main,
          checkLatestPostsBlock ? styles.addMarginNegative : ''
        )}
      >
        {post?.template?.templateName === 'Page Static' ? (
          <>
            <div className={styles.teaser}>
              <Container removePaddingBottom>
                <h1>{post?.title}</h1>
              </Container>
            </div>
            <Container>
              <div className={styles.pageStaticWrapper}>{children}</div>
            </Container>
          </>
        ) : (
          children
        )}
      </main>
      <Footer
        social={seo?.social}
        siteTitle={seo?.siteTitle}
        menu={footerMenu}
        menuLegal={legalMenu}
        themeOptions={themeOptions}
        removeMarginTopFooter={removeMarginTopFooter}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  paddingTop: PropTypes.oneOf(['sm', 'md']),
  ...seoPropTypes
}

Layout.defaultProps = {
  paddingTop: 'md'
}

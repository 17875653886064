import {createHash} from 'crypto'
import {NextApiRequest} from 'next'

const verifyCsrfToken = (req: NextApiRequest, tokenToCheck: string) => {
  const secret: string = process.env.APP_SECRET_STRING || ''
  const csrfMethods = ['POST', 'PUT', 'PATCH', 'DELETE']

  if (!req.method || (csrfMethods && !csrfMethods.includes(req.method))) {
    // we dont need to check the CSRF if it's not within the method.
    return true
  }

  try {
    const useSecureCookies = process.env.NEXTAUTH_URL?.startsWith('https://')
    const csrfProp = `${useSecureCookies ? '__Host-' : ''}next-auth.csrf-token`

    if (req.cookies[csrfProp]) {
      const cookieValue = req.cookies[csrfProp]
      const cookieSplitKey = cookieValue?.match('|') ? '|' : '%7C'
      const splitResult = cookieValue?.split(cookieSplitKey)
      if (!splitResult) {
        // handle the case when splitResult is undefined
        return false
      }

      const [csrfTokenValue, csrfTokenHash] = splitResult

      const generatedHash = createHash('sha256')
        .update(`${tokenToCheck}${secret}`)
        .digest('hex')

      if (csrfTokenHash === generatedHash) {
        // If hash matches then we trust the CSRF token value
        if (csrfTokenValue === tokenToCheck) return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export default verifyCsrfToken

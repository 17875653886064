import {
  LoginFormValues,
  RegistrationFormValues,
  ResetPasswordFormValues,
  SetNewPasswordFormValues
} from '@/components/types/auth'
import {generateRandomUsername} from '@/functions/utils'
import {validatePassword} from '@/functions/validatePassword'
import ResetPassword from '@/functions/wordpress/auth/resetPassword'
import SendResetPassword from '@/functions/wordpress/auth/sendResetPassword'
import verifyCsrfToken from '@/functions/wordpress/auth/verifyCsrfToken'
import {NextApiRequest, NextApiResponse} from 'next'
import {signIn} from 'next-auth/react'

export default async function submitForm(
  values: LoginFormValues,
  setErrorMessage: (message: string) => void = () => {},
  setSuccessMessage: (message: string) => void = () => {},
  handleCloseForm: () => void = () => {},
  csrfToken: string,
  isFormOpen: boolean,
  isMoodleActivated: boolean,
  router: any
) {
  const {username: username, password: password} = values

  const response = await signIn('wpLogin', {
    username,
    password,
    redirect: false,
    headers: {'X-CSRF-Token': csrfToken}
  })

  if (response?.error) {
    setErrorMessage(response.error)
  } else {
    setSuccessMessage('success:loginSuccess')
    if (isFormOpen && !isMoodleActivated) router.push('/konto')
    handleCloseForm()
  }
}

export async function handler(req: NextApiRequest, res: NextApiResponse) {
  const csrfToken = req.headers['x-csrf-token']

  if (typeof csrfToken !== 'string') {
    res.status(400).json({error: 'Invalid CSRF token'})
    return
  }

  const isCsrfTokenValid = verifyCsrfToken(req, csrfToken)

  if (!isCsrfTokenValid) {
    res.status(403).json({error: 'Invalid CSRF token'})
    return
  }
}

export async function submitRegistrationForm(
  values: RegistrationFormValues,
  setIsRegistrierenSuccess: (email: boolean) => void = () => {},
  setSuccessMessage: (message: string) => void = () => {},
  setErrorMessage: (message: string) => void = () => {},
  csrfToken: string
) {
  const {
    firstName: firstName,
    lastName: lastName,
    email: email,
    emailConfirm: emailConfirm,
    password: password,
    passwordConfirm: passwordConfirm
  } = values

  if (email !== emailConfirm) {
    setErrorMessage('error:emailAddressesDoNotMatch')
    return
  }

  if (password !== passwordConfirm) {
    setErrorMessage('error:passwordsDoNotMatchError')
    return
  }
  const passwordError = validatePassword(password)

  if (passwordError) {
    setErrorMessage(passwordError)
    return
  }

  const username = generateRandomUsername()

  const response = await signIn('wpRegister', {
    firstName,
    lastName,
    username,
    email,
    password,
    redirect: false,
    headers: {'X-CSRF-Token': csrfToken}
  })

  if (response?.error) {
    setErrorMessage(response.error)
  } else {
    setIsRegistrierenSuccess(true)
    setSuccessMessage('success:registerSuccess')
  }
}

export async function sendPasswordResetEmail(
  values: ResetPasswordFormValues,
  setIsEmailSuccess: (email: boolean) => void = () => {},
  setSuccessMessage: (message: string) => void = () => {},
  setErrorMessage: (message: string) => void = () => {}
) {
  const {username: username} = values

  const response = await SendResetPassword(username)
  if (response === true) {
    setIsEmailSuccess(true)
    setSuccessMessage('success:emailSentSuccess')
  } else {
    setErrorMessage('error:emailDoesNotexist')
  }
}

export async function submitNewPassword(
  values: SetNewPasswordFormValues,
  userLoginFromUrl?: string,
  keyFromUrl?: string,
  setIsNewPasswordSuccess: (password: boolean) => void = () => {},
  setSuccessMessage: (message: string) => void = () => {},
  setErrorMessage: (message: string) => void = () => {}
) {
  const {password: newPassword, confirmPassword: confirmPassword} = values

  if (newPassword !== confirmPassword) {
    setErrorMessage('error:passwordsDoNotMatchError')
    return
  }

  const passwordError = validatePassword(newPassword)

  if (passwordError) {
    setErrorMessage(passwordError)
    return
  }

  const response = await ResetPassword(
    userLoginFromUrl,
    keyFromUrl,
    newPassword
  )

  if (response?.success) {
    setIsNewPasswordSuccess(true)
    setSuccessMessage('success:passwordUpdateSuccess')
  } else {
    setErrorMessage(response?.errorMessage)
  }
}

import Icon from '@/components/atoms/Icon'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import Link from 'next/link'

import styles from './LangSwitcher.module.scss'

export default function LangSwitcher() {
  const {post}: any = useWordPressContext()
  const translationExists = post?.translations?.length > 0

  const defaultSlug = translationExists
    ? post?.isFrontPage
      ? '/'
      : post.translations[0].slug
    : ''

  return translationExists ? (
    <div className={styles.langswitcher}>
      <Link
        href={defaultSlug}
        locale={post?.language?.code === 'EN' ? 'de' : 'en'}
      >
        <a>
          <Icon icon="language" size={20} sizeViewbox={20} />

          <div className={styles.language}>
            {post?.language?.code === 'EN' ? 'DE' : 'EN'}
          </div>
        </a>
      </Link>
    </div>
  ) : null
}

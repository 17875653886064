import Icon from '@/components/atoms/Icon'
import cn from 'classnames'
import {Form as FormikForm, Formik, FormikProps} from 'formik'
import React from 'react'
import styles from './Form.module.scss'

type FormProps = {
  children:
    | React.ReactNode
    | ((formikProps: FormikProps<any>) => React.ReactNode)
  className?: string
  formDefaults: object
  title?: string
  id?: string
  registrieren?: boolean
  validationSchema?: object
  page?: boolean
  onSubmit: (values: any) => void
  onClose: () => void
}

export default function Form({
  children,
  className,
  formDefaults,
  id,
  validationSchema,
  registrieren,
  page,
  onSubmit,
  onClose
}: FormProps) {
  return (
    <Formik
      initialValues={formDefaults}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <>
          <FormikForm
            id={id}
            className={cn(
              styles.form,
              className,
              registrieren && styles.registrierenForm,
              page && styles.page
            )}
          >
            <div
              className={cn(
                styles.iconWrapper,
                registrieren && styles.registrierenForm
              )}
            >
              <Icon
                size={40}
                sizeViewbox={40}
                icon="closeForm"
                className={styles.closeForm}
                onClick={() => onClose()}
              />
            </div>
            <div className={styles.formContainer}>
              {typeof children === 'function'
                ? (children as (formikProps: any) => React.ReactNode)(
                    formikProps
                  )
                : children}
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  )
}

import Button from '@/components/atoms/Button/Button'
import Checkbox from '@/components/atoms/Inputs/Checkbox/Checkbox'
import Text from '@/components/atoms/Inputs/Text'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import {FormType} from '@/components/types/auth'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import React from 'react'
import styles from './Profile.module.scss'

type RegistrationFormProps = {
  formikProps: {
    setFieldValue: (field: string, value: any) => void
    values: {
      firstName: string
      lastName: string
      username: string
      email: string
      emailConfirm: string
      password: string
      passwordConfirm: string
      newsletter: string
      termsCheckbox: boolean
      emailOnly: boolean
    }
  }
  isRegistrierenSuccess?: boolean
  isCheckout?: boolean
  isLoading?: boolean
  handleSwitchFormType: (formType: FormType) => void
  handleCloseForm: () => void
  csrfToken: string
  isKonto?: boolean
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  formikProps,
  isRegistrierenSuccess,
  isCheckout,
  isLoading,
  handleSwitchFormType,
  handleCloseForm,
  isKonto
}) => {
  const {t} = useTranslation(['checkout', 'global', 'account'])
  const router = useRouter()
  const {themeOptions}: any = useWordPressContext()

  return (
    <>
      {isCheckout && !isKonto ? (
        <>
          <div className={styles.title}>{t('checkout:checkoutLabel')}</div>
          {/* <div className={styles.subtitle}>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </div> */}

          <div className={styles.titleWrapper}>
            <div className={styles.formTitle}>{t('account:registerLabel')}</div>
            <div className={styles.questionWrapper}>
              {t('account:alreadyRegistered')}
              <div className={styles.underlindedButton}>
                <div onClick={() => handleSwitchFormType(FormType.Login)}>
                  {t('account:loginLabel')}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.title}>
          {isRegistrierenSuccess
            ? t('account:closeRegister')
            : t('account:registerLabel')}
        </div>
      )}

      {isRegistrierenSuccess ? (
        <>
          <div className={styles.subtitle}>
            {t('account:successfulRegistration')}
          </div>
          <div className={styles.bottomButtons}>
            <div className={styles.buttonWrapper}>
              {isCheckout ? (
                <Button
                  url="/"
                  tag="button"
                  type="filled"
                  text={t('global:toFrontPageButton')}
                  icon={null}
                />
              ) : (
                <Button
                  tag="button"
                  text={t('account:closeLabel')}
                  type="filled"
                  icon={null}
                  onClick={() => handleCloseForm()}
                />
              )}
            </div>
            <div className={styles.underlindedButton}>
              <div
                onClick={() => {
                  handleCloseForm()
                  router.push('/konto')
                }}
              >
                {t('account:viewAccountLabel')}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.fields}>
            <div className={styles.halfLength}>
              <Text
                id="firstName"
                label={`${t('checkout:firstNameLabel')} `}
                placeholder={t('checkout:firstNameLabel')}
                isRequired
                type="text"
                setFieldValue={formikProps.setFieldValue}
              />

              <Text
                id="lastName"
                label={`${t('checkout:lastNameLabel')} `}
                placeholder={t('checkout:lastNameLabel')}
                isRequired
                type="text"
                setFieldValue={formikProps.setFieldValue}
              />
            </div>

            <Text
              id="email"
              label={`${t('checkout:emailLabel')} `}
              placeholder={t('checkout:emailLabel')}
              isRequired
              type="EMAIL"
              fullWidth={true}
              setFieldValue={formikProps.setFieldValue}
            />

            <Text
              id="emailConfirm"
              label={`${t('checkout:confirmEmailLabel')} `}
              placeholder={t('checkout:confirmEmailLabel')}
              isRequired
              type="EMAIL"
              fullWidth={true}
              setFieldValue={formikProps.setFieldValue}
            />
            <div className={styles.passwordPolicyWrapper}>
              <div className={styles.passwords}>
                <Text
                  id="password"
                  label={`${t('account:passwordLabel')} `}
                  placeholder={t('account:passwordLabel')}
                  isRequired
                  type="password"
                  setFieldValue={formikProps.setFieldValue}
                />

                <Text
                  id="passwordConfirm"
                  label={`${t('account:confirmpasswordLabel')} `}
                  placeholder={t('account:confirmpasswordLabel')}
                  isRequired
                  type="password"
                  setFieldValue={formikProps.setFieldValue}
                />
              </div>
              <div className={styles.passwordPolicy}>
                {t('account:passwordPolicies')}
              </div>
            </div>
          </div>
          <div className={styles.checkBoxes}>
            {/* <Checkbox
              id="newsletter"
              label={`${t('account:newsletterText')} `}
              login={true}
              setFieldValue={formikProps.setFieldValue}
            /> */}
            <Checkbox
              id="termsCheckbox"
              label={themeOptions.consent}
              isRequired
              setFieldValue={formikProps.setFieldValue}
            />
          </div>

          <div className={styles.bottomButtons}>
            <Button
              tag="button"
              text={
                isCheckout
                  ? t('account:registerAndcontinue')
                  : t('account:registerLabel')
              }
              type="filled"
              icon={null}
              isLoading={isLoading}
              disabled={
                !formikProps.values.email ||
                !formikProps.values.emailConfirm ||
                !formikProps.values.password ||
                !formikProps.values.passwordConfirm ||
                !formikProps.values.firstName ||
                !formikProps.values.termsCheckbox
              }
            />

            {!isCheckout && (
              <div className={styles.underlindedButton}>
                <div onClick={() => handleSwitchFormType(FormType.Login)}>
                  {t('account:loginLabel')}
                </div>
                <Checkbox
                  id="emailOnly"
                  label="contact_me_by_email_only"
                  className={styles.honey}
                  login={true}
                  setFieldValue={formikProps.setFieldValue}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default RegistrationForm

import {gql} from '@apollo/client'

// Mutation: Validate key for reset password in WP.
const mutationResetPassword = gql`
  mutation RESET_PASSWORD($resetUserPasswordInput: ResetUserPasswordInput!) {
    resetUserPassword(input: $resetUserPasswordInput) {
      user {
        id
      }
    }
  }
`

export default mutationResetPassword

import Container from '@/components/atoms/Container'
import Icon from '@/components/atoms/Icon'
import cn from 'classnames'
import {bannerSettings} from 'config'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import Cookies from 'universal-cookie'
import styles from './Banner.module.scss'
/**
 *
 * @param  root0
 * @param  root0.link
 * @param  root0.text
 */
export default function Banner({link, text}) {
  const [cookie, setCookie] = useState(false)
  const cookies = new Cookies()

  useEffect(() => {
    const cookieBanner = cookies.get('Banner')
    if (cookieBanner) {
      setCookie(cookieBanner)
    }
  })

  return (
    <div className={cn(styles.fixed, cookie ? styles.isHidden : '')}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <Icon
              className={styles.infoIcon}
              icon="informationBlackOutline"
              title="Updates"
            />
            UPDATES
          </div>
          <div className={styles.textLinkWrapper}>
            <div className={styles.text}>{text}</div>
            <a className={styles.link} href={link.url} target={link.target}>
              {link.title}
            </a>
          </div>
          <Icon
            icon="close"
            className={styles.closeIcon}
            title="close Banner"
            onClick={() => {
              setCookie(true)
              cookies.set('Banner', true, bannerSettings)
            }}
          />
        </div>
      </Container>
    </div>
  )
}

Banner.propTypes = {
  text: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string
  })
}

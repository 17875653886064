import cn from 'classnames'

import NavigationMenu from '../NavigationMenu/NavigationMenu'

import styles from './Navigation.module.scss'

import {MenuItem} from '@/components/types/types'

type Props = {
  menu: MenuItem[]
  menuLegal: MenuItem[]
  className: string
  menuOpen: boolean
}

export default function Navigation({
  menu,
  menuLegal,
  className,
  menuOpen
}: Props) {
  return menu?.length ? (
    <nav
      className={cn(styles.navigation, className, menuOpen && styles.menuOpen)}
    >
      <ul className={styles.menu}>
        <NavigationMenu menuLegal={menuLegal} menu={menu} active={true} />
      </ul>
    </nav>
  ) : null
}

import Button from '@/components/atoms/Button/Button'
import Checkbox from '@/components/atoms/Inputs/Checkbox/Checkbox'
import Text from '@/components/atoms/Inputs/Text'
import {FormType} from '@/components/types/auth'
import cn from 'classnames'
import {useTranslation} from 'next-i18next'
import styles from './Profile.module.scss'

type LoginFormProps = {
  formikProps: {
    setFieldValue: (field: string, value: any) => void
    values: {
      username: string
      password: string
      emailOnly: boolean
    }
  }
  handleSwitchFormType: (formType: FormType) => void
  isCheckout?: boolean
  isLoading?: boolean
  csrfToken: string
  isKonto?: boolean
}

const LoginForm = ({
  formikProps,
  handleSwitchFormType,
  isCheckout,
  isLoading,
  isKonto
}: LoginFormProps) => {
  const {t} = useTranslation(['checkout', 'global', 'account'])
  return (
    <>
      {isCheckout && !isKonto ? (
        <>
          <div className={styles.title}>{t('checkout:checkoutLabel')}</div>
          {/* <div className={styles.subtitle}>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </div> */}
          <div className={styles.titleWrapper}>
            <div className={styles.formTitle}> {t('account:loginLabel')}</div>

            <div className={styles.questionWrapper}>
              {t('account:notRegistered')}
              <div className={styles.underlindedButton}>
                <div onClick={() => handleSwitchFormType(FormType.Register)}>
                  {t('account:registerLabel')}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.title}>{t('account:loginLabel')}</div>
      )}

      <div className={styles.fields}>
        <Text
          id="username"
          label={`${t('checkout:emailLabel')} `}
          placeholder={t('checkout:emailLabel')}
          isRequired
          type="EMAIL"
          fullWidth={true}
          setFieldValue={formikProps.setFieldValue}
        />

        <Text
          id="password"
          label={`${t('account:passwordLabel')} `}
          placeholder={t('account:passwordLabel')}
          isRequired
          type="password"
          fullWidth={true}
          setFieldValue={formikProps.setFieldValue}
        />

        <div className={styles.forgotPassword}>
          <div className={styles.underlindedButton}>
            <div onClick={() => handleSwitchFormType(FormType.ResetPassword)}>
              {t('account:forgotPasswordLabel')}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(styles.bottomButtons, isCheckout && [styles.isCheckout])}
      >
        <div className={styles.loginWrapper}>
          <Button
            tag="button"
            text={
              isCheckout
                ? t('account:loginAndcontinue')
                : t('account:loginLabel')
            }
            type="filled"
            icon={null}
            isLoading={isLoading}
            disabled={
              !formikProps.values.username || !formikProps.values.password
            }
          />
        </div>
        {!isCheckout && (
          <div className={styles.underlindedButton}>
            <div onClick={() => handleSwitchFormType(FormType.Register)}>
              {t('account:registerLabel')}
            </div>
            <Checkbox
              id="emailOnly"
              label="contact_me_by_email_only"
              className={styles.honey}
              login={true}
              setFieldValue={formikProps.setFieldValue}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default LoginForm

import {gql} from '@apollo/client'

// Mutation: Reset a password in WP.
const mutationSendResetPassword = gql`
  mutation RESET_USER(
    $sendPasswordResetEmailInput: SendPasswordResetEmailInput!
  ) {
    sendPasswordResetEmail(input: $sendPasswordResetEmailInput) {
      user {
        username
      }
    }
  }
`

export default mutationSendResetPassword

export function validatePassword(password: string): string | null {
  if (password.length < 8) {
    return 'error:shortPasswordError'
  }
  if (!/[A-Z]/.test(password)) {
    return 'error:capitalLetterMissingError'
  }
  if (!/[a-z]/.test(password)) {
    return 'error:smallLetterMissingError'
  }
  if (!/[0-9]/.test(password)) {
    return 'error:numberMissingError'
  }

  return null
}

import {useEffect, useState} from 'react'

async function getCsrfToken() {
  const response = await fetch('/api/auth/csrf/')
  const data = await response.json()
  return data.csrfToken
}

export default function useCsrfToken() {
  const [csrfToken, setCsrfToken] = useState('')

  useEffect(() => {
    getCsrfToken().then((token) => setCsrfToken(token))
  }, [])

  return csrfToken
}

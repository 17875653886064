import Icon from '@/components/atoms/Icon'
import useOutsideAlerter from '@/components/atoms/OutsideAlerter/OutsideAlerter'
import {useOverlayContext} from '@/components/common/ProfileOverlayContext'
import {FormType} from '@/components/types/auth'
import {enableScroll} from '@/functions/enableDisableScroll'
import isLinkActive from '@/functions/isLinkActive'
import cn from 'classnames'
import {useSession} from 'next-auth/react'
import {useRouter} from 'next/router'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import FormWrapper from './FormWrapper'
import styles from './Profile.module.scss'

const Profile = () => {
  const router = useRouter()

  const {query, asPath} = router
  const userLoginFromUrl = query['user-login'] as string | undefined
  const resetPasswordFromUrl = query['reset-password'] as string | undefined
  const moodleFromUrl = query['moodle'] as string | undefined
  const {t} = useTranslation()

  const keyFromUrl = query.key as string | undefined
  const {isFormOpen, setIsFormOpen, setIsMoodleActivated} = useOverlayContext()
  const {data: session} = useSession()
  const [formType, setFormType] = useState(FormType.Login)
  const ref = useRef(null)
  useOutsideAlerter({
    ref,
    isOpen: isFormOpen,
    setIsOpen: setIsFormOpen,
    isOverlay: true
  })

  const handleOpenForm = () => {
    if (session && session.user) {
      router.push('/konto')
    } else {
      enableScroll()
      setIsFormOpen(true)
      setFormType(FormType.Login)
    }
  }

  useEffect(() => {
    if (userLoginFromUrl) {
      setIsFormOpen(true)
      setFormType(FormType.SetNewPassword)
    } else if (resetPasswordFromUrl) {
      setIsFormOpen(true)
      setFormType(FormType.ResetPassword)
    } else if (moodleFromUrl) {
      setIsFormOpen(true)
      setIsMoodleActivated(true)
    }
  }, [userLoginFromUrl, resetPasswordFromUrl, moodleFromUrl])

  return (
    <div
      ref={ref}
      className={cn(styles.profile, isFormOpen && [styles.formOpen])}
    >
      <span
        onClick={handleOpenForm}
        className={cn(
          styles.profileIcon,
          isLinkActive(asPath, 'konto') && styles.active
        )}
      >
        <Icon icon="profile" size={24} sizeViewbox={24} title="konto" />
        <span>{t('global:accountNavLabel')}</span>
      </span>
      <FormWrapper
        formType={formType}
        setFormType={setFormType}
        userLoginFromUrl={userLoginFromUrl}
        keyFromUrl={keyFromUrl}
        isPage={false}
        isCheckout={false}
        isKonto={false}
      />
    </div>
  )
}

export default Profile

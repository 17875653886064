import {disableScroll} from '@/functions/enableDisableScroll'
import React, {MouseEvent, RefObject, useEffect} from 'react'

interface OutsideAlerterProps {
  ref: RefObject<HTMLElement>
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isOverlay: boolean
}

export default function useOutsideAlerter({
  ref,
  isOpen,
  setIsOpen,
  isOverlay
}: OutsideAlerterProps): void {
  useEffect(() => {
    if (!isOpen && isOverlay) disableScroll()
  }, [isOpen, isOverlay])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        isOpen
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside as any)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as any)
    }
  }, [ref, isOpen, setIsOpen])
}

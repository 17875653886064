import Link from 'next/link'

import PropTypes from 'prop-types'

import Container from '@/components/atoms/Container'

import Logo from '@/components/atoms/Logo'

import Icon from '@/components/atoms/Icon'
import cn from 'classnames'

import {useContext} from 'react'

import {enableScroll} from '../../../functions/enableDisableScroll'

import {ExternalArrow} from '../../atoms/Icons'

import {CookiesFormContext} from '../../blocks/custom/Cookies/CookiesFormContext'
import styles from './Footer.module.scss'

/**
 * Render the Footer component.
 *
 * @author                           WebDevStudios
 * @param  {object}  props              The component attributes as props.
 * @param  {object}  props.social       Yoast SEO social media data.
 * @param  {object}  props.menu         Arrary of menu items.
 * @param            props.menuLegal
 * @param            props.themeOptions
 * @param  {string}  props.siteTitle    Yoast SEO site title.
 * @return {Element}                    The Footer component.
 */
export default function Footer({
  siteTitle,
  menu,
  menuLegal,
  themeOptions,
  removeMarginTopFooter
}) {
  const {setIsCookiesFormOpen} = useContext(CookiesFormContext)

  const handleCookiesForm = () => {
    setIsCookiesFormOpen(true)
    enableScroll()
  }
  return (
    <footer
      className={cn(
        styles.footer,
        removeMarginTopFooter ? styles.removeMarginTopFooter : ''
      )}
    >
      <Container removePaddingBottom>
        <div className={styles.inner}>
          <div className={styles.contactWrapper}>
            <Link href="/">
              <a aria-label="click to go home">
                <Logo type="light" className={styles.logo} />
              </a>
            </Link>

            <div className={styles.contact}>
              <div>{themeOptions?.address}</div>
              <div>{themeOptions?.telephone}</div>
              <div>
                <a href={`mailto:${themeOptions?.emailInTheFooter}`}>
                  {themeOptions?.emailInTheFooter}
                </a>
              </div>
            </div>
          </div>
          {!!menu && (
            <nav className={styles.menu}>
              {menu.map((item, index) => {
                const children =
                  item.children && item.children.length > 0 ? item.children : ''
                return (
                  <div key={index} className={styles.section}>
                    {children ? (
                      <>
                        <div className={styles.headline}>{item.label}</div>
                        <div className={styles.col}>
                          {children.map((child, indexChild) => {
                            return (
                              <Link href={child.path} key={indexChild}>
                                <a
                                  target={child.target ? child.target : '_self'}
                                  className={styles.link}
                                >
                                  {child.label}
                                  {child.MenuACF.isSocial && <ExternalArrow />}
                                </a>
                              </Link>
                            )
                          })}
                        </div>
                      </>
                    ) : (
                      <Link href={item.path} className={styles.link}>
                        <a target={item.target ? item.target : '_self'}>
                          {item.label}
                        </a>
                      </Link>
                    )}
                  </div>
                )
              })}
            </nav>
          )}
        </div>
        <Icon
          icon="paymentMethods"
          className={cn(styles.paymentMethods, styles.hideOnMobile)}
          size={282}
          size2={24}
          sizeViewbox={282}
          sizeViewbox2={24}
        />
        {!!menuLegal && (
          <nav className={styles.legal}>
            {menuLegal.map((item, index) => {
              return item.legalMenu?.isCookies ? (
                <div
                  key={index}
                  onClick={() => handleCookiesForm()}
                  className={styles.linkLegal}
                >
                  {item.label}
                </div>
              ) : (
                <Link href={item.path} key={index}>
                  <a
                    target={item.target ? item.target : '_self'}
                    className={styles.linkLegal}
                  >
                    {item.label}
                  </a>
                </Link>
              )
            })}
            <Icon
              icon="paymentMethods"
              className={styles.paymentMethods}
              size={282}
              size2={24}
              sizeViewbox={282}
              sizeViewbox2={24}
            />
            <div className={styles.copyright}>
              &copy; {new Date().getFullYear()} {siteTitle}
            </div>
          </nav>
        )}
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  // ...seoSocialPropTypes,
  siteTitle: PropTypes.string,
  themeOptions: PropTypes.shape({
    address: PropTypes.string,
    email: PropTypes.string,
    telephone: PropTypes.string,
    socials: PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string,
        link: PropTypes.shape({
          url: PropTypes.string
        })
      })
    )
  })
}

import mutationResetPassword from '@/lib/wordpress/auth/mutationResetPassword'
import {initializeWpApollo} from '@/lib/wordpress/connector'

export default async function ResetPassword(login, key, password) {
  const apolloClient = initializeWpApollo()

  return apolloClient
    .mutate({
      mutation: mutationResetPassword,
      variables: {
        resetUserPasswordInput: {
          login,
          key,
          password
        }
      }
    })
    .then((response) => {
      if (response?.data?.resetUserPassword?.user?.id) {
        return {
          success: true,
          message: 'Password reset successful.'
        }
      } else {
        return {
          error: true,
          errorMessage: 'An error occurred while trying to reset the password.'
        }
      }
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error.message
      }
    })
}

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState
} from 'react'

const defaultState = {
  isCookiesFormOpen: false
}

interface CookiesFormContextI {
  isCookiesFormOpen: boolean
  setIsCookiesFormOpen?: Dispatch<SetStateAction<boolean>>
}

export const CookiesFormContext =
  createContext<CookiesFormContextI>(defaultState)

export const CookiesFormProvider = ({
  children
}: PropsWithChildren<Record<string, any>>) => {
  const [isCookiesFormOpen, setIsCookiesFormOpen] = useState(
    defaultState.isCookiesFormOpen
  )

  return (
    <CookiesFormContext.Provider
      value={{isCookiesFormOpen, setIsCookiesFormOpen}}
    >
      {children}
    </CookiesFormContext.Provider>
  )
}

import React, {useEffect, useState} from 'react'
import {Cart} from '../types/cart'

interface AppContextProps {
  cart?: Cart
  setCart: React.Dispatch<React.SetStateAction<any>>
  isPaymentSuccess: boolean
  setIsPaymentSuccess: React.Dispatch<React.SetStateAction<boolean>>
  pspReference: string
  setPspReference: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  successMessage: string
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
}

export const AppContext = React.createContext<AppContextProps>({
  cart: undefined,
  setCart: () => {},
  isPaymentSuccess: false,
  setIsPaymentSuccess: () => {},
  pspReference: '',
  setPspReference: () => {},
  isLoading: false,
  setIsLoading: () => {},
  errorMessage: '',
  setErrorMessage: () => {},
  successMessage: '',
  setSuccessMessage: () => {}
})

export const AppProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [cart, setCart] = useState<any>(null)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false)
  const [pspReference, setPspReference] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  useEffect(() => {
    // @TODO Will add an option to show the cart with localStorage later.
    if (process.browser) {
      let cartData = localStorage.getItem('woo-next-cart')
      cartData = cartData !== null ? JSON.parse(cartData) : ''
      setCart(cartData)
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        isPaymentSuccess,
        setIsPaymentSuccess,
        pspReference,
        setPspReference,
        isLoading,
        setIsLoading,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

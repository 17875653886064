import cn from 'classnames'

import NextLink from 'next/link'

import React from 'react'

import styles from './Button.module.scss'

import Icon from '@/components/atoms/Icon'

import Loading from '@/components/molecules/Loading'

type buttonInnerProps = {
  icon: string | null
  iconLeft?: boolean
  text: string
  iconSize?: number
}

export function ButtonInner({
  icon,
  text,
  iconLeft,
  iconSize
}: buttonInnerProps) {
  return (
    <>
      {!iconLeft && <div>{text}</div>}
      {icon && (
        <div className={styles.icon}>
          <Icon
            icon={icon}
            size={iconSize}
            sizeViewbox={iconSize}
            title={text}
            ariaHidden={text ? true : false}
          />
        </div>
      )}
      {iconLeft && <div>{text}</div>}
    </>
  )
}

type buttonProps = {
  attributes?: object
  className?: string
  disabled?: boolean
  fluid?: boolean
  icon?: string | null
  iconOnly?: boolean
  iconLeft?: boolean
  iconSize?: number
  onClick?: () => void
  styleOutline?: boolean
  tag?: string
  text: string
  url?: string
  urlExternal?: boolean
  isAnchor?: boolean
  isSmall?: boolean
  type?: string
  isLoading?: boolean
}

export default function Button({
  attributes,
  className,
  disabled,
  fluid,
  icon = 'arrowRightOutline',
  iconOnly,
  iconLeft,
  iconSize,
  onClick,
  styleOutline,
  tag = 'a',
  text,
  url,
  urlExternal,
  isAnchor,
  type = 'primary',
  isSmall,
  isLoading
}: buttonProps) {
  const buttonClassNames = cn(
    styles.button,
    className,
    iconOnly && styles.iconOnly,
    iconLeft && styles.iconLeft,
    fluid && styles.fluid,
    type && styles[type],
    isSmall && styles.isSmall,
    disabled && styles.disabled,
    styleOutline && styles.styleOutline,
    isLoading && styles.isLoading
  )

  if (url) {
    return urlExternal || isAnchor ? (
      <a
        href={url}
        className={buttonClassNames}
        aria-label={text}
        rel="noreferrer"
        target={urlExternal ? '_blank' : '_self'}
        {...attributes}
      >
        <ButtonInner
          icon={icon}
          iconSize={iconSize}
          iconLeft={iconLeft}
          text={text}
        />
      </a>
    ) : (
      <NextLink href={url}>
        <a className={buttonClassNames} aria-label={text} {...attributes}>
          <ButtonInner
            icon={icon}
            iconSize={iconSize}
            iconLeft={iconLeft}
            text={text}
          />
        </a>
      </NextLink>
    )
  } else {
    return (
      // Render element with default button tag.
      React.createElement(
        `${tag}`,
        {
          className: buttonClassNames,
          'aria-label': text,
          onClick,
          ...attributes,
          disabled,
          'aria-disabled': disabled
        },
        <>
          {isLoading && <Loading single={true} />}
          <ButtonInner
            icon={icon}
            iconSize={iconSize}
            iconLeft={iconLeft}
            text={text}
          />
        </>
      )
    )
  }
}

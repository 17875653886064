import {KeyboardArrowDown} from '@material-ui/icons'

import cn from 'classnames'

import Link from 'next/link'

import {useRouter} from 'next/router'

import {useEffect, useState} from 'react'

import {lg} from 'config'

import styles from './Header.module.scss'

import Logo from '@/components/atoms/Logo'

import MenuToggle from '@/components/atoms/MenuToggle/MenuToggle'

import Profile from '@/components/blocks/custom/Profile/Profile'

import Navigation from '@/components/molecules/Navigation'

import isLinkActive from '@/functions/isLinkActive'

import ScrollDirection from '@/functions/ScrollDirection'

import useMediaQuery from '@/functions/useMediaQuery'

import NavigationDesktop from '@/components/molecules/NavigationDesktop'

import LangSwitcher from '@/components/molecules/LangSwitcher'
import {MenuItem} from '@/components/types/types'
import {disableScroll, enableScroll} from '@/functions/enableDisableScroll'

type Props = {
  menu: MenuItem[]
  menuLegal: MenuItem[]
}

export default function Header({menu, menuLegal}: Props) {
  const [menuOpen, setMenuOpen] = useState(false)

  const [subMenu, setSubMenu] = useState('')

  const [hideHeader, setHideHeader] = useState(false)

  const router = useRouter()

  const {asPath} = router

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false)
    }
  }, [router.asPath])

  const scrollDirection = ScrollDirection()

  const isLg = useMediaQuery(lg)

  const toggleDesktopMenu = (id: string) => {
    if (subMenu === id) {
      setMenuOpen(false)
      setSubMenu('')
    } else {
      setMenuOpen(true)
      setSubMenu(id)
    }
  }

  const generateLinks = (menu: MenuItem[]) => {
    return (
      <div className={styles.links}>
        {menu?.map((item: MenuItem, i: number) => {
          return item.children && item.children?.length ? (
            <span
              className={cn(
                styles.link,
                subMenu === item.id ? styles.active : ''
              )}
              key={i}
              onClick={() => toggleDesktopMenu(item.id)}
            >
              {item.label}
              <KeyboardArrowDown />
            </span>
          ) : item.path.startsWith('#') ? (
            <a className={styles.link} href={item.path}>
              {item.label}
            </a>
          ) : (
            <Link href={item.path} locale={false} key={i}>
              <a
                target={item.target ? item.target : '_self'}
                className={cn(
                  isLinkActive(asPath, item.path) && !menuOpen && styles.active,
                  styles.link
                )}
              >
                {item.label}
              </a>
            </Link>
          )
        })}
        <LangSwitcher />
        <Profile />
      </div>
    )
  }

  const navigateHome = (e: any) => {
    e.preventDefault()
    setMenuOpen(false)
    if (router.asPath !== '/') {
      router.push('/')
    }
  }

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset > 600 && !menuOpen && scrollDirection === 'down') {
        setHideHeader(true)
      } else {
        setHideHeader(false)
      }
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDirection])

  useEffect(() => {
    if (menuOpen) {
      enableScroll()
    } else {
      disableScroll()
    }
  }, [menuOpen])

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false)
    }
    window.scrollTo(0, 0)
  }, [router.asPath])

  return (
    <header
      className={cn(
        styles.header,
        menuOpen ? styles.menuOpen : ' ',
        hideHeader ? styles.hideNav : styles.showNav
      )}
    >
      <div className={styles.inner}>
        <div className={styles.navigation}>
          <a onClick={navigateHome} href="#">
            <Logo className={styles.logo} />
          </a>
          {isLg ? (
            generateLinks(menu)
          ) : (
            <>
              <Profile />
              <MenuToggle menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            </>
          )}
        </div>
        {isLg ? (
          <NavigationDesktop
            menu={menu}
            menuOpen={menuOpen}
            activeSubMenuID={subMenu}
          />
        ) : (
          <Navigation
            menu={menu}
            menuLegal={menuLegal}
            menuOpen={menuOpen}
            className={styles.primaryMenu}
          />
        )}
      </div>
    </header>
  )
}

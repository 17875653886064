export const enableScroll = () => {
  document.body.style.top = `-${window.pageYOffset}px`
  document.body.classList.add('no-scroll')
}

export const disableScroll = () => {
  const windowTop = document.body.style.top
  document.body.classList.remove('no-scroll')
  window.scrollTo(0, windowTop ? windowTop.match(/\d+/)[0] : 0)
  document.body.style.top = '0px'
}
